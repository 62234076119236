import { Identifier } from "react-admin";

export const arrayToChoices = (array: Identifier[]) =>
  array.map((item) => ({ id: item.toString(), name: item.toString() }));

export const enumToChoices = (enumObject: object) => {
  return Object.entries(enumObject)
    .filter(([key]) => isNaN(Number(key))) // Filter out numeric keys
    .map(([key, value]) => ({
      id: value,
      name: key,
    }));
};

export const enumToValueArray = (enumObject: object) => {
  return Object.entries(enumObject)
    .filter(([key]) => isNaN(Number(key))) // Filter out numeric keys
    .map(([key, value]) => value);
};
