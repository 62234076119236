import { myMSALObj, tokenRequest } from "auth/authConfig";
import axios, { AxiosRequestConfig } from "axios";
import { stringify } from "query-string";
import { DataProvider, fetchUtils, UpdateParams } from "react-admin";

export const axiosRequest = async ({ method, url, data = {}, params = {}, headers = {} }: AxiosRequestConfig) => {
  const account = myMSALObj.getActiveAccount();
  const authResult = await myMSALObj.acquireTokenSilent({
    account: account || undefined,
    ...tokenRequest,
  });
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authResult.accessToken}`,
  };
  const combinedHeaders = { ...defaultHeaders, ...headers };

  return axios({
    method,
    url,
    data,
    params,
    headers: combinedHeaders,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Axios request failed:", error);
      throw error;
    });
};

export const httpClient = async (url: string, options: any = {}) => {
  const account = myMSALObj.getActiveAccount();
  let authResult;

  try {
    // Attempt to silently acquire a token
    authResult = await myMSALObj.acquireTokenSilent({
      account: account || undefined,
      ...tokenRequest,
    });
  } catch (error) {
    console.error("Error acquiring token silently", error);
    // If acquireTokenSilent fails, fallback to a popup or redirect
    try {
      authResult = await myMSALObj.acquireTokenPopup({
        ...tokenRequest,
      });
    } catch (popupError) {
      console.error("Error acquiring token with popup", popupError);
      throw popupError;
    }
  }

  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
    });
  }
  options.headers.append("Authorization", `Bearer ${authResult.accessToken}`);
  return fetchUtils.fetchJson(url, options);
};

export default (apiUrl: string): DataProvider => {
  return {
    getList: async (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        page: page,
        size: perPage,
        ...params.filter,
      };
      if (field) {
        query.order_by = (order == "ASC" ? "+" : "-") + field;
      }
      if (query.q) {
        query.search = params.filter.q;
        delete query.q;
      }
      if (params.meta && params.meta.urlParams) {
        resource = `${resource}${params.meta.urlParams}`;
      }
      const url = `${apiUrl}/${resource}?${stringify(query, {
        arrayFormat: "separator",
        arrayFormatSeparator: ",",
      })}`;
      const { json } = await httpClient(url);
      return {
        data: json.items,
        total: json.total,
        page: json.page,
        size: json.size,
      };
    },

    getOne: async (resource, params, url = null) => {
      let metaUrlQuery = "";
      if (params.meta && params.meta.urlParams) {
        metaUrlQuery = params.meta.urlParams;
      }
      const { json } = url
        ? await httpClient(url)
        : await httpClient(`${apiUrl}/${resource.replace(/\/$/, "")}/${params.id}${metaUrlQuery}`);
      return {
        data: json,
      };
    },

    getMany: async (resource, params) => {
      const query = {
        id__in: params.ids,
      };
      const url = `${apiUrl}/${resource}?${stringify(query, {
        arrayFormat: "separator",
        arrayFormatSeparator: ",",
      })}`;
      const { json } = await httpClient(url);
      return { data: json.items };
    },

    getManyReference: async (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      const { headers, json } = await httpClient(url);
      return {
        data: json,
        total: parseInt(headers.get("content-range")?.split("/").pop() || "0", 10),
      };
    },

    create: async (resource, params) => {
      if (params.meta && params.meta.urlParams) {
        resource = `${resource}/${params.meta.urlParams}`;
      }
      const url = `${apiUrl}/${resource}`;
      const { json } = await httpClient(url, {
        method: "POST",
        body: JSON.stringify(params.data),
      });
      return {
        data: { ...json },
      };
    },

    update: (resource, params: UpdateParams & { meta?: { method?: "PUT" | "PATCH" } }) => {
      let method = "PUT";
      if (params.meta && params.meta.method) {
        method = params.meta.method;
      }
      return httpClient(`${apiUrl}/${resource.replace(/\/$/, "")}/${params.id}`, {
        method,
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        if (!("id" in json) && params.id) {
          json.id = params.id;
        }
        return { data: json };
      });
    },

    updateMany: async (resource, params) => {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        method: "PUT",
        body: JSON.stringify(params.data),
      });
      return { data: json };
    },

    delete: (resource, params) => {
      return httpClient(`${apiUrl}/${resource.replace(/\/$/, "")}/${params.id}`, {
        method: "DELETE",
      }).then(({ json }) => ({ data: json }));
    },

    deleteMany: async (resource, params) => {
      const query = {
        ids: JSON.stringify(params.ids),
        soft: true,
      };
      const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        method: "DELETE",
        body: JSON.stringify(params.ids),
      });
      return { data: json.deleted_ids };
    },
  };
};
