import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import GroupsIcon from "@mui/icons-material/Groups";
import { Box, Card, CardContent, Chip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MaskType, SiteType } from "components/entities/tsoTests/testsAfrrRte/types";
import IEChargeIcon from "components/icons/IEChargeIcon";
import { baseResources } from "config_infos";
import { GroupTypeEnum, MaintenanceTypeEnum } from "enums/globalEnums";
import { groupModeChoices } from "enums/reactAdminChoices";
import { useCallback, useEffect, useState } from "react";
import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  FieldProps,
  FilterList,
  FilterListItem,
  Identifier,
  List,
  Loading,
  ReferenceInput,
  SelectInput,
  ShowButton,
  SortPayload,
  TextField,
  TextInput,
  useDataProvider,
  useRecordContext,
} from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";
import { useFetchSiteStatuses } from "./hooks/useFetchSiteStatuses";

const GroupFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, minWidth: 180 }}>
    <CardContent>
      <FilterList label="Groupe type" icon={<GroupsIcon />}>
        <FilterListItem label="EDR" value={{ type: GroupTypeEnum.rte_edr }} />
        <FilterListItem label="EDP" value={{ type: GroupTypeEnum.rte_edp }} />
        <FilterListItem label="EDC" value={{ type: GroupTypeEnum.rte_edc }} />
        <FilterListItem label="EDA" value={{ type: GroupTypeEnum.rte_eda }} />
        <FilterListItem label="PE" value={{ type: GroupTypeEnum.fingrid_pe }} />
        <FilterListItem label="RO" value={{ type: GroupTypeEnum.fingrid_ro }} />
      </FilterList>
    </CardContent>
  </Card>
);

export const GroupList = () => {
  const groupFilters = [
    <ReferenceInput source="id__in" reference={baseResources.sites.GROUPS} key="id__in" label="name" alwaysOn>
      <AutocompleteInput
        optionText={({ id, name, code }: { id: Identifier; name: string; code: string }) => {
          return `${id} - ${name} - ${code}`;
        }}
        label="name"
      />
    </ReferenceInput>,
    <TextInput label="Code" source="code" key="code" />,
    <TextInput label="Code DSO" source="code_dso" key="code_dso" />,
    <SelectInput label="Mode" source="mode" key="mode" choices={groupModeChoices} />,
  ];

  return (
    <List filters={groupFilters} aside={<GroupFilterSidebar />}>
      <Datagrid rowClick={"expand"} bulkActionButtons={false} expand={<SiteTable />} expandSingle>
        <TextField source="name" />
        <TextField source="code" sortable={false} />
        <TextField source="mode" sortable={false} />
        <PlanningMaskField source="current_planning_mask" />
        <SiteNumberTextField source="Site number" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SiteNumberTextField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  return <span>{record.site_ids.length}</span>;
};

export const PlanningMaskField = (props: FieldProps) => {
  const record = useRecordContext(props);
  const dataprovider = useDataProvider();
  const [mask, setMask] = useState<MaskType | null>(null);

  const fetchMasks = useCallback(
    async (site_id: Identifier) => {
      const now = new Date();
      const { data } = await dataprovider.getList(baseResources.plannings.PLANNING_MASKS, {
        sort: { field: "id", order: "ASC" } as SortPayload,
        pagination: { page: 1, perPage: 1 },
        filter: {
          site_id,
          start_date__lt: now.toISOString(),
          end_date__gt: now.toISOString(),
        },
      });
      return data;
    },
    [dataprovider],
  );

  useEffect(() => {
    if (!record.site_ids.length) return;
    const firstSiteId = record.site_ids[0];

    fetchMasks(firstSiteId)
      .then((data: MaskType[]) => {
        setMask(data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [fetchMasks, record.site_ids]);

  if (!record || !mask) return null;
  return record ? <span>{mask?.operating_mode}</span> : null;
};

const SiteTable = () => {
  const record = useRecordContext();
  const { statuses, loading } = useFetchSiteStatuses(record.site_ids || []);
  console.log("statuses:", statuses);

  const getRelatedStatus = (site_id: Identifier) => {
    return statuses.find((status: any) => status.site_id === site_id) || {};
  };

  if (!record || !record.sites) return null;

  return (
    <Box sx={{ width: "100%", bgcolor: "aliceBlue" }}>
      <Typography variant="h6" gutterBottom pl={2}>
        Sites
      </Typography>
      <TableContainer component={Paper} sx={{ bgcolor: "aliceBlue" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>automate ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>IECharge</TableCell>
              <TableCell>Box State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.site_ids.map((id: Identifier) => (
              <SiteRow key={id} id={id} status={getRelatedStatus(id)} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

type MaintenanceType = MaintenanceTypeEnum | null;
type SiteRowProps = { id: Identifier; status: { [key: string]: any } };

const SiteRow = ({ id, status }: SiteRowProps) => {
  const dataprovider = useDataProvider();
  const [site, setSite] = useState<SiteType | null>(null);
  const [maintenanceType, setMaintenanceType] = useState<MaintenanceType>(null);
  const [loading, setloading] = useState(true);

  const fetchSite = useCallback(async () => {
    const { data } = await dataprovider.getOne(baseResources.sites.SITES, {
      id,
    });
    return data;
  }, [dataprovider, id]);

  const fetchMaintenance = useCallback(async () => {
    const now = new Date();
    const { data } = await dataprovider.getList(baseResources.maintenance.MAINTENANCE, {
      sort: { field: "start", order: "ASC" } as SortPayload,
      pagination: { page: 1, perPage: 1 },
      filter: {
        site_id: id,
        start__lte: now.toISOString(),
        end__gte: now.toISOString(),
      },
    });
    return data;
  }, [dataprovider, id]);

  const getBoxState = useCallback(
    (newMaintenanceType: MaintenanceType) => {
      const isStatusEmpty =
        Object.keys(status).length === 0 ||
        !status.network ||
        !status.control ||
        !status.measure ||
        !status.sequence ||
        !status.power_clamping ||
        !status.aggregator_module;

      if (isStatusEmpty) {
        return <Chip variant="outlined" icon={<CloseIcon />} label="status" color="error" />;
      }

      const maintenanceTypes: MaintenanceType[] = [
        MaintenanceTypeEnum.Enedis,
        MaintenanceTypeEnum.Preventive,
        MaintenanceTypeEnum.Fortuit,
      ];

      if (maintenanceTypes.includes(newMaintenanceType)) {
        return <Chip variant="outlined" icon={<SettingsIcon />} label={newMaintenanceType} color="warning" />;
      }

      return <Chip variant="outlined" icon={<CheckIcon />} label="operational" color="success" />;
    },
    [status],
  );

  useEffect(() => {
    setloading(true);
    Promise.allSettled([fetchSite(), fetchMaintenance()])
      .then(([siteResult, maintenanceResult]) => {
        if (siteResult.status === "fulfilled") {
          setSite(siteResult.value);
        }
        if (maintenanceResult.status === "fulfilled" && maintenanceResult.value.length > 0) {
          setMaintenanceType(maintenanceResult.value[0]?.type);
          console.log("maintenanceResult.value:", maintenanceResult.value);
        }
        setloading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setloading(false);
      });
  }, [fetchSite, fetchMaintenance]);

  if (loading)
    return (
      <TableRow>
        <TableCell rowSpan={4}>
          <Loading />
        </TableCell>
      </TableRow>
    );
  if (!site) return null;
  return (
    <TableRow key={site.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>{site.id}</TableCell>
      <TableCell>{site.name}</TableCell>
      <TableCell>{site?.iecharge && <IEChargeIcon style={{ width: "20px", height: "20px" }} />}</TableCell>
      <TableCell>
        {/* {maintenanceType ? <CheckIcon sx={{ color: "green" }} /> : <CloseIcon sx={{ color: "red" }} />} */}
        {getBoxState(maintenanceType)}
      </TableCell>
    </TableRow>
  );
};
