import { Box } from "@mui/material";
import { generateJwtToken } from "auth/jwtUtils";
import { subHours } from "date-fns";
import { SiteContryEnum } from "enums/globalEnums";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Identifier, Loading, useRecordContext } from "react-admin";

type SiteTelemetryProps = {
  siteId: Identifier;
  country: SiteContryEnum;
};

const SiteTelemetry = () => {
  const record = useRecordContext();
  return <SiteTelemetryGrafanaChart siteId={record.id} country={record.country} />;
};

export const SiteTelemetryGrafanaChart: React.FC<SiteTelemetryProps> = ({ siteId, country }) => {
  const [jwtToken, setJwtToken] = useState<string | null>(null);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  useEffect(() => {
    async function fetchJwtToken() {
      const privateKey = process.env.REACT_APP_GRAFANA_API_KEY as string;
      const token = await generateJwtToken(privateKey);
      if (token) {
        setJwtToken(token);
      }
    }

    fetchJwtToken();
  }, []);

  const getCountrySlug = useCallback((country: SiteContryEnum) => {
    switch (country) {
      case SiteContryEnum.Finland:
        return "de404w8ezefb4c/operations-finlande";
      case SiteContryEnum.France:
        return "ce1mnuvm20gzka/operations-france";
      default: // defaults to France
        return "ce1mnuvm20gzka/operations-france";
    }
  }, []);

  const handleRefresh = () => {
    setRefreshKey((prev) => prev + 1); // Increment refreshKey to refresh the iframe
  };

  if (!jwtToken) {
    return <Loading />;
  }

  const now = new Date();
  const to = now.getTime();
  const from = subHours(now, 1).getTime();

  const BASE_GRAFANA_URL = "https://joulestream.data.nwstorm.io";
  const countrySlug = getCountrySlug(country);

  return (
    <Box>
      <Button onClick={handleRefresh} label="Refresh chart" variant="contained" sx={{ mb: 2 }} />

      <Box minHeight={500} height={500}>
        <iframe
          key={refreshKey} // Forces iframe to reload when refreshKey changes
          src={`${BASE_GRAFANA_URL}/d-solo/${countrySlug}?orgId=1&from=${from}&to=${to}&panelId=2&var-site_id=${siteId}&auth_token=${jwtToken}`}
          width="100%"
          height="100%"
          title="Grafana Dashboard"
        ></iframe>
      </Box>
    </Box>
  );
};

export default SiteTelemetry;
