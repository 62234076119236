import { Stack, Typography } from "@mui/material";
import CustomDialog from "components/commons/dialogs/CustomDialog";
import useOpenClose from "hooks/useOpenClose";
import { useCallback } from "react";
import { Button, SaveButton, Toolbar, useDataProvider, useNotify, useRecordContext, useRedirect } from "react-admin";

type CustomToolbarProps = {
  isCreateMode: boolean;
  resource: string;
  resourceKey: string;
  children: React.ReactNode;
};

const CustomDeletedDialogToolbar = ({ isCreateMode, resource, resourceKey, children }: CustomToolbarProps) => {
  const notify = useNotify();
  const { open, handleToggle } = useOpenClose();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const record = useRecordContext();

  const handleDelete = useCallback(async () => {
    try {
      await dataProvider.delete(resource, { id: record.id });
      notify(`${resourceKey} deleted successfully`, { type: "success" });

      redirect("list", resource);
    } catch (error: any) {
      console.error("error:", error);
      notify(`Error deleting ${resourceKey}`, { type: "error" });
    }
    handleToggle();
  }, [dataProvider, notify, record, redirect, handleToggle, resource, resourceKey]);

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      {children}
      {/* <SaveButton />*/}
      {!isCreateMode && <Button label="Delete" onClick={handleToggle} size="large" color="error" variant="contained" />}
      <CustomDialog
        open={open}
        onClose={handleToggle}
        title={"Confirmation"}
        actionComponentContent={
          <Stack direction={"row"} spacing={2} width={"100%"} justifyContent={"center"}>
            <Button onClick={handleToggle} variant="outlined" label="Cancel" size="large" />
            <Button onClick={handleDelete} variant="contained" color="error" label="Delete" size="large" />
          </Stack>
        }
      >
        <Typography>Are you sure you want to delete this {resourceKey}?</Typography>
      </CustomDialog>
    </Toolbar>
  );
};

export default CustomDeletedDialogToolbar;
